<template>
  <tab-container>
    <div slot="operation">
      <a-range-picker
        style="width: 380px"
        :show-time="{
          defaultValue: [
            $moment('00:00:00', 'HH:mm:ss'),
            $moment('23:59:59', 'HH:mm:ss'),
          ],
        }"
        format="YYYY-MM-DD"
        @ok="onChangeTimeRange"
        @change="initData"
      />
    </div>
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :row-key="record => record.pkId"
      :loading="loading"
      :scroll="{ x: 1400 }"
      @change="handlePagination"
    >
      <template slot="images" slot-scope="text, record">
        <span v-for="(item, index) in record.images" :key="index">
          <x-oss-image
            v-if="item"
            basePath="/oss/iot/oss"
            :ossPath="item"
            @click="openImg(record.images, index)"
            class="table-img"
        /></span>
      </template>
    </a-table>
  </tab-container>
</template>

<script>
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import TabContainer from '@/views/green-construction/components/tab-container.vue';
import { deviceTypeEnum } from '@/enum/device';
import RecordTable from '@/views/green-construction/components/mixins/record-table';
import { deviceWbRecord } from '@/services/device-manage/device-detail';
import { UploadService } from '@triascloud/services';
import { Preview } from '@triascloud/x-components';

@Component({
  components: {
    TabContainer,
  },
})
export default class WeighingLog extends mixins(RecordTable) {
  get columns() {
    return [
      {
        dataIndex: 'index',
        customRender: (text, row, index) => (
          <span>{(this.pagination.current - 1) * 10 + index + 1}</span>
        ),
        title: this.$t('safety.index'),
        fixed: 'left',
        align: 'center',
        width: 80,
      },
      {
        align: 'left',
        title: this.$t('carManagement.plateNumber'),
        dataIndex: 'plateNumber',
        ellipsis: true,
      },
      {
        align: 'left',
        title: this.$t('carManagement.entryTime'),
        dataIndex: 'inTime',
        width: 180,
        ellipsis: true,
        customRender: txt =>
          txt ? this.dayjs(txt).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        align: 'left',
        title: `${this.$t('carManagement.entryWeight')}(KG)`,
        dataIndex: 'inWeight',
      },
      {
        align: 'left',
        title: this.$t('carManagement.departureTime'),
        dataIndex: 'outTime',
        width: 180,
        ellipsis: true,
        customRender: txt =>
          txt ? this.dayjs(txt).format('YYYY-MM-DD HH:mm:ss') : '',
      },
      {
        align: 'left',
        title: `${this.$t('carManagement.leaveWeight')}(KG)`,
        dataIndex: 'outWeight',
      },
      {
        align: 'left',
        title: `${this.$t('carManagement.incomingWeight')}(KG)`,
        dataIndex: 'useWeight',
      },
      {
        align: 'left',
        title: '过磅图片',
        dataIndex: 'images',
        width: 300,
        ellipsis: true,
        scopedSlots: { customRender: 'images' },
      },
    ];
  }
  async openImg(pictures, index) {
    const basePath = '/oss/iot/oss';
    const customService = new UploadService(basePath);
    const abPath = await customService.getAuth(pictures);
    const imgNameList = ['车前', '车后', '车顶', '磅房'];
    const urls = abPath.map((item, index) => ({
      url: item,
      name: imgNameList[index],
    }));
    Preview.createModal({
      urls,
      index,
    });
  }
  getRecordList(queryParams, deviceGroup) {
    return deviceWbRecord(queryParams, deviceGroup);
  }
  deviceGroup = deviceTypeEnum.WB.deviceGroup;
}
</script>

<style lang="less" module></style>
